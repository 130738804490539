//

/* eslint-disable react/no-danger */

import React from 'react';

import { Link } from 'gatsby';

import { FaRegClock, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

import { DiscussionEmbed } from 'disqus-react';

import { siteType, pageMetaDataType, blogMarkdownRemarkType } from '../types';

const BlogPostLayout = (props) => {
	// console.log('BlogPostLayout; props ', props);
	const { site, page, markdownRemark, pageData } = props;
	const { prevNode, nextNode } = pageData;
	// console.log('BlogPostLayout; site ', site, ' page ', page);
	// console.log('BlogPostLayout; markdownRemark ', markdownRemark);
	// console.log('prevNode ', prevNode);
	// console.log('nextNode ', nextNode);

	const { config } = site.siteMetadata;
	const { fields, frontmatter, html } = markdownRemark;

	const disqusShortname = `${config.disqusShortname}`;
	const disqusUrl = `${config.siteUrl}${markdownRemark.fields.slug}`;
	const disqusConfig = {
		identifier: markdownRemark.id,
		title: page.title,
		url: disqusUrl
	};
	// console.log('disqusShortname ', disqusShortname);
	// console.log('disqusConfig ', disqusConfig);

	return (
		<article id='post'>
			<div className='container'>
				<div>

					<div>
						<h1>{page.title}</h1>
						<p className='lead'>
							{'by '}
							{config.author}
						</p>
						<hr />
						<p>
							<FaRegClock className='blog-icon' />
							{' Posted on '}
							{fields.postDate}
						</p>
						<hr />

						{/* <!-- Post Content --> */}
						{frontmatter.lead && <p className='lead'>{frontmatter.lead}</p>}

						<div dangerouslySetInnerHTML={{ __html: html }} />

						{prevNode && (
							<span>
								<Link rel='prev' to={prevNode.fields.slug}>
									<FaAngleDoubleLeft className='arrow-left-icon' />
									{prevNode.frontmatter.title}
								</Link>
							</span>
						)}
						{nextNode && (
							<span className='pull-right'>
								<Link rel='next' to={nextNode.fields.slug}>
									{nextNode.frontmatter.title}
									<FaAngleDoubleRight className='arrow-right-icon' />
								</Link>
							</span>
						)}

						<hr />
						<DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
						<hr />

					</div>

				</div>
			</div>
		</article>
	);
};

BlogPostLayout.propTypes = {
	site: siteType.isRequired,
	page: pageMetaDataType.isRequired,
	markdownRemark: blogMarkdownRemarkType.isRequired
};

export default BlogPostLayout;
